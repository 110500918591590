import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

function DashboardTimer() {
    const [now, setNow] = useState(dayjs());

    useEffect(() => {
        const timer = setInterval(() => {
            setNow(dayjs());
        }, 60000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', pl: 11, fontSize: 14 }}>
            {now.format('YYYY년 MM월 DD일 HH:mm')}
        </Typography>
    );
}

export default DashboardTimer;
